import React, { useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

const URL_PRESIGNED_POST = 'https://api.mingus.ai/v1/presignedPost';
const POLLING_URL = 'https://mingus.ai/artifact/watermark/';

const uploadFile = async (file, setProgress, setSubmit, setImageUrl, startPolling) => {
    setSubmit(true);
    let response = await axios.get(URL_PRESIGNED_POST);
    const uuid = response.data.fields.key.split('/').pop();

    const formData = new FormData();
    Object.entries(response.data.fields).forEach(([key, value]) => {
        formData.append(key, value);
    });
    formData.append('file', file);

    let interval;
    const config = {
        onUploadProgress: function () {
            /* Update to simulate a 8-second progress */
            const progressBarIncrement = 100 / 80;
  
            let progressBarStatus = 0;
            interval = setInterval(() => {
                if (progressBarStatus < 100) {
                    progressBarStatus += progressBarIncrement;
                    setProgress(progressBarStatus);
                } else {
                    clearInterval(interval);
                }
            }, 100);
        },
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };

    axios
        .post(response.data.url, formData, config)
        .then(() => {
            clearInterval(interval); // Clear the interval when the upload is successful
            startPolling(POLLING_URL + uuid + ".jpeg");
            setSubmit(false);
        })
        .catch((error) => {
            console.log(error);
            clearInterval(interval);
            setSubmit(false);
        });
};

function FileUploader() {
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const [isSubmitInProgress, setSubmitInProgress] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [isPollingInProgress, setPollingInProgress] = useState(false);

    const attemptToLoadImage = async (pollUrl, attempts, intervalId) => {
        try {
            const response = await axios.get(pollUrl, {responseType: 'blob'});

            if (response.status >= 200 && response.status < 300) {
                clearInterval(intervalId);
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                setImageUrl(blobUrl);
                setPollingInProgress(false);
            } else if (attempts >= 10) {
                clearInterval(intervalId);
                setPollingInProgress(false);
            }
        } catch (error) {
            if (attempts >= 10) {
                clearInterval(intervalId);
                setPollingInProgress(false);
            }
        }
    };

    const startPolling = (pollUrl) => {
        setPollingInProgress(true);
        let attempts = 0;
        const intervalId = setInterval(() => {
            attempts++;
            attemptToLoadImage(pollUrl, attempts, intervalId);
        }, 2000);
    };

    const onDrop = useCallback((acceptedFiles) => {
        setImageUrl(null); // Reset the imageUrl
        setFiles(acceptedFiles.map(file => Object.assign(file, {preview: URL.createObjectURL(file)})));
    }, []);

    useEffect(() => {
        files.forEach(file => uploadFile(file, setProgress, setSubmitInProgress, setImageUrl, startPolling));
    }, [files]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {!imageUrl ? (
                <>
                    {isDragActive ?
                        <p>Yup, right here..</p>
                    :
                        isSubmitInProgress ?
                            <p>Hang on tight..</p>
                        :
                            isPollingInProgress ?
                                <p>Uncle Mingus is working..</p>
                                :
                                    <p>Heh, drag a file over to uncle Mingus.</p>
                    }
                    <div>
                        {isPollingInProgress ? null :
                            (!progress && imageUrl === null &&
                                files.map((file, index) => (
                                    <div key={file.name}>
                                        <img
                                            src={file.preview}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                objectFit: 'contain'
                                            }}
                                            alt='preview'
                                        />
                                    </div>
                                ))
                            )
                        }
                    </div>
                    {progress > 0 && imageUrl === null && <progress value={progress} max='100' />}
                </>
            ) :
                <img src={imageUrl} alt="" />
            }
        </div>
    );
}

export default FileUploader;